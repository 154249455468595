import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { Spin } from 'antd';

import * as serviceWorker from './serviceWorker';
import AuthProvider from 'providers/AuthProvider';

const AppLayout = lazy(() => import('components/layout/App/AppLayout'));

import './index.css';
import 'components/layout/App/App.less';

const container: HTMLElement = document.getElementById('root') as HTMLElement;

const fallbackSpin = () => (
  <div style={{ margin: '50px 0', textAlign: 'center' }}>
    <Spin />
  </div>
);

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <Suspense fallback={fallbackSpin()}>
          <AppLayout />
        </Suspense>
      </Router>
    </AuthProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
