export const INVENTORY_MANAGEMENT_TABS = {
  STOCK_TRANSFER: 'Stock Transfer',
  AUDIT_MANAGER: 'Audit Manager',
  BARCODE_MANAGER: 'Barcode Manager',
  LAYAWAY_MANAGER: 'Layaway Manager',
  INITIAL_PANES: [
    { title: 'Stock Transfer' },
    { title: 'Audit Manager' },
    // { title: 'Barcode Manager' }, //hotfix - EVB-2445 hiding barcode-manager tab until barcode print issue fixed.
    { title: 'Layaway Manager' },
  ],
};

export const STOCK_TRANSFER_LABELS = {
  TITLES: {
    TRANSFER_MANAGER: 'Transfer Manager',
    CREATE_EDIT_TRANSFER: 'Create Edit Transfer',
    CREATE_NEW_TRANSFER: 'Create New Transfer',
    EDIT_TRANSFER: 'Edit Transfer',
    ACCEPT_TRANSFER: 'Accept Transfer',
    CLOSED_TRANSFER: 'Closed Transfer',
  },
  TRANSFER_MANAGER: {
    VIEWING: 'Viewing',
    FROM: 'From',
    TO: 'To',
  },
  CREATE_NEW_TRANSFER: {
    DEPARTMENT: 'Department',
    DESTINATION: 'Destination',
    BARCODE: 'Barcode',
    APPROVAL_TYPE: 'Sent',
    REFERENCE: 'Reference',
    COURIER_DETAIL: 'Courier Detail',
  },
  ACCEPT_TRANSFER: {
    SOURCE: 'Source',
    BARCODE: 'Barcode',
    APPROVAL_TYPE: 'Recieved',
  },
  ALERT_BUTTON_LABEL: 'Save and Create New Transfer',
  AUTHORIZATION_MSG:
    'The colleague entered has confirmed the content(s) of this transfer are correct and true.',
  ERROR_MSG: {
    BARCODE_NOT_FOUND: 'Entered barcode not found.',
    BARCODE_REQUIRED: 'Barcode is required',
  },
  PRINT_LABEL: 'Print Transfer',
  PRINT_BARCODE: 'Print Barcode(s)',
};

export const BARCODE_MANAGER_LABELS = {
  TITLES: {
    BARCODE_MANAGER: 'Barcode Manager',
  },
  TABLE_HEADINGS: {
    BARCODE: 'Barcode',
    DESCRIPTION: 'Description',
    ITEMTYPE: 'Item Type ',
    LOCATION: 'Location',
    QUCIKKAT: 'QuickCat/SKU',
    PRICE: 'List Price/Due Customer/ Supplier',
  },
  FOOTER_BUTTON_LABEL: 'Print Barcode',
};

export const STOCK_TRANSFER_ERROR = {
  NO_CLOSE_TRANSFER_FOUND: 'There are no Closed Transfers for this date range',
  NO_ACTIVE_TRANSFER_FOUND: 'There are no Active transfers for this date range',
};

export const STOCK_TRANSFER_STATUS = {
  CLOSED: 'closed',
};

export const PAGINATION = {
  DEFAULT_CURRENT_PAGE: 1,
  DEFAULT_PAGE_SIZE: 100,
};

export const FILTERED_STORES = {
  JC: 6118,
  ONLINE: 6100,
};
