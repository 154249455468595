export const PAGE_TITLE = {
  PICKUP: 'Pickup/Renew',
  PURCHASE: 'Purchase Return',
};

export const PICKUP = 'PICKUP';

export const AGREEMENT_OPTIONS = [
  { id: 1, value: 'Pickup' },
  { id: 2, value: 'Renew' },
  { id: 3, value: 'Paydown' },
  { id: 4, value: 'Partial Pay' },
  { id: 5, value: 'Withdraw' },
  { id: 6, value: 'Affidavit Only' },
];

export const LOAN_STATUS = {
  CONFISCATED: 'Confiscated',
  VULNERABLE: 'Vulnerable',
  OPEN: 'Open',
  PICKED_UP: 'Picked Up',
  WITHDRAWN: 'Withdrawn',
  WITHDRAW_LATER: 'Withdraw Later',
  EARLY_SETTLEMENT: 'Early Settlement',
  IS_AT_RISK: 'Soft Locked',
  LOCKED: 'Locked',
  RENEWAL: 'Renewal',
  PAYDOWN: 'Paydown',
  PFI_AUCTION: 'PFI Auction',
  PARTIAL_PAY: 'Partial Pay',
  AFFIDAVIT_ICON: 'Affidavit',
  CHANGE_OWNERSHIP: 'Change Ownership',
  WRITTEN_OFF: 'Written Off',
};

export const AFFIDAVIT_ICON = 'Affidavit';

export const ACTIONS_ON_CUSTOMER_NOT_PRESENT = [1, 4]; // Actions available on agreement when customer is not in front
export const ACTION_WITHDRAW = [5];
export const ACTION_AFFI_ONLY = [6];
export const ACTIONS_NOT_WAIVE = [4, 6]; // waive interest not applicable for partial pay and affi only
export const NEW_CONTRACT = [2, 3]; // New contract when Renew or Paydown
export const CHANGE_OWNERSHIP = 'CO';

export const PLEDGE_LABEL = {
  VIEW_PLEDGE_HISTORY: 'View Pledge History',
  REMOVE: 'Remove',
  CHANGE_CUSTOMER: 'Change Customer',
  AUTHORISE: 'Authorise',
  PROCEED_TO_BASKET: 'Proceed to Basket',
  EXIT: 'Exit',
};

export const PLEDGE_HISTORY = {
  TITLE: 'Pledge History',
  DATE: 'Date',
  TRANSACTION: 'Transaction',
  AMOUNT: 'Amount',
  DUE_TO_DATE: 'Due to Date',
};

export const PLEDGE_LOAN_DETAILS = {
  COST_TO_RENEW: 'Cost to renew loan(s)',
  COST_TO_PICKUP: 'Cost to pickup loan(s)',
  TOTAL_NEW_LOANS: 'Total new loans',
  TOTAL_TAKE_FROM_CUSTOMER: 'Total take from customer',
  TOTAL_INTEREST_WAIVED: 'Total interest charges waived',
  TAKE_FROM_CUSTOMER: 'Take from Customer',
};

export const ERROR_MSG = {
  AGREEMENT_NOT_FOUND: 'Agreement Not Found',
  AGREEMENT_ALREADY_LISTED: 'This agreement is already being displayed ',
  ACTION_NOT_SELECTED: 'Please select an action before proceeding to basket. ',
  ACTION_NOT_SELECTED_ON_EXIT_AND_SAVE:
    'Not all agreement listed have an action selected, please remove the agreement you do not wish to transact against or select the relevant action in order to save to the basket',
  AGREEMENT_EXISTS_IN_BASKET:
    'This agreement is already currently in the customers basket, please manage from your basket',
  ITEMS_NOT_FOUND: 'Items not found for this agreement.',
};

export const NOTE = {
  PICKUP:
    'Note: If ‘No’, then this customer will only be allowed to Pickup or Partial Pay. Alternatively, if ownership of this contract is passed over then you will be allowed to accept Renewal and Paydown payments.',
  PURCHASE_PFI_ITEM:
    'The contents of this purchase have since been processed for retail stock or already returned',
  CONFISCATED_AGREEMENT:
    'This pledge agreement has been marked Confiscated. No actions will be available, please refer to the Confiscations manager within Pledge management to update the status.',
  VULNERABLE_AGREEMENT:
    'This pledge agreement has been marked Vulnerable. No actions will be available, please refer to the Vulnerable manager within Pledge management to update the status.',
};

export const PAID_PAYMENT_DETAILS = {
  DATE: 'Date',
  AMOUNT_PAID: 'Amount Paid',
  PAYMENT_LOCATION: 'Payment Location',
  METHOD_OF_PAYMENT: 'Method of Payment',
};

export const WAIVE_INTEREST = {
  AGREEMENT_NO: 'Agreement Number',
  START_DATE: 'Start Date',
  DUE_DATE: 'Due Date',
  NO_OF_DAYS: 'No. of Days',
  DAILY: 'Daily',
  DUE_TODAY: 'Due Today',
  SELECT_PERIOD: 'Select Period',
  AMOUNT_WAIVED: 'Amount Waived',
  REASON_FOR_WAIVING: 'Reason for waiving interest',
  WAIVE_AFFIDAVIT_FEE: 'Waive Affidavit Fee',
  VALIDATION_SELECT_PERIOD: {
    REQUIRED: 'Select Period is required',
    MIN_DAYS: 'Select Period cannot be less than 0',
    MAX_DAYS: 'Select Period cannot be greater than',
    ONLY_NUMBERS: 'Select Period can only accept numbers',
  },
  VALIDATION_REASON_WAIVED: {
    REQUIRED: 'Reason for waiving interest is required.',
    MAX: 'Maximum characters allowed in Reason for waiving interest is 600.',
    MIN: 'Minimumn 10 characters are required in Reason for waiving interest.',
  },
  VULNERABLE_DAYS: {
    TITLE: 'Vulnerable',
    DAYS_WAIVED: 'No. of days waived: ',
    AMOUNT: 'Amount:',
  },
};

export const PARTIAL_PAY = {
  LABEL_AMT_TO_PAY: 'Enter Amount Customer Wishes to Pay',
  NOTE: 'The amount must be less than the total amount due to date , up to £0.01 off the current outstanding balance, or you should cancel and go to Pickup option.',
  ALERT_WAIVE_INTEREST:
    'Interest waived against this agreement will be removed. Waiving interest is only permitted when picking up, renewing or paying down a loan.',
  VALIDATION_AMT_TO_PAY: {
    REQUIRED: 'Amount customer wishes to pay is required',
    ZERO: 'Amount customer wishes to pay cannot be 0',
    ONLY_NUMBERS:
      'Amount customer wishes to pay can only accept numbers and decimals upto 2 places',
    AMOUNT_LESS_THAN_DUE_DATE:
      'Any partial payments must be less than the amount due to date',
  },
};

export const PAYDOWN = {
  NEW_LOAN_AMOUNT: 'newLoanAmount',
  WISH_TO_PAY: 'wishToPay',
  VALIDATION_NEW_LOAN: {
    REQUIRED: 'New loan amount is required',
    ZERO: 'New loan amount cannot be 0 or negative ',
    ONLY_NUMBERS:
      'New loan amount can only accept numbers and decimals upto 2 places',
    AMOUNT_LESS_THAN_PRINCIPAL:
      'The New Loan amount must be less than the principal loan and more than 0',
  },
  VALIDATION_WISH_TO_PAY: {
    REQUIRED: 'Amount customer wishes to pay is required',
    ZERO: 'Amount customer wishes to pay cannot be 0',
    ONLY_NUMBERS:
      'Amount customer wishes to pay can only accept numbers and decimals upto 2 places',
    AMOUNT_LESS_THAN_PICKUP:
      'The amount the customer wishes to pay must be less than the cost to pick up',
    AMOUNT_MORE_THAN_INTEREST:
      'The amount the customer wishes to pay must be more than the interest occurred to date',
  },
};

export const AFFIDAVIT = {
  AGREEMENT_NO: 'Agreement Number',
  CUSTOMER_NAME: 'Customer Name',
  LOAN_AMOUNT: 'Loan Amount',
  AFFIDAVIT_FEE: 'Affidavit Fee',
  REASON_FOR_AFFIDAVIT: 'Reason for affidavit',
  REQUIRED_REASON_VALIDATION: 'Reason for affidavit is required',
  AFFIDAVIT_CUSTOMER_MESSAGE:
    'Affidavit can only be printed for customer present in front.',
};

export const WITHDRAW = {
  TITLE: 'The customer is withdrawing this Pledge!',
  METHOD: 'Method of Notification',
  IN_PERSON: 'In Person',
  RIGHT_TO_WITHDRAW:
    'The customer has chosen to exercise their right to withdraw within the first 14 days of this agreement.',
};

export const PURCHASE_RETURN_DETAILS = {
  COST_TO_PICKUP_SELECTED: 'Cost to pickup selected item(s)',
  COST_TO_PICKUP_ALL: 'Cost to pickup all item(s)',
};

export const TOGGLE_SWITCH = {
  PLEDGE: 'Pledge',
  PURCHASE: 'Purchase',
};

export const PURCHASE_ERROR_MSG = {
  NOT_FOUND: 'Purchase Not Found',
  ALREADY_LISTED: 'This purchase is already being displayed ',
  EXISTS_IN_BASKET:
    'This purchase is already currently in the customers basket, please manage from your basket',
  ITEMS_NOT_FOUND: 'Items not found for this purchase.',
  SELECT_AN_ITEM: 'Please select an item for return',
};

export const AUCTION_ALERT_MSG = {
  COMMON_MSG_1: 'This agreement has been processed for the auction dated ',
  AT_STORE_MSG: ' and is currently held at store.',
  AT_AUCTION_MSG: ' and is currently held at auction.',
  COMMON_MSG_2:
    'Picking up or renewing this loan will pass the lots and remove it from the auction.',
  COMMON_MSG_3: 'Partial payments will not automatically pass the loan.',
  AT_AUCTION_MSG_2:
    'However the customer will only be notified when the item(s) have been returned to store.',
};

export const AUCTION_LOCK_ALERT_MSG = {
  COMMON_MSG_1: 'This agreement has been processed for the auction dated ',
  IS_LOCKED_MSG: ' and is now locked.',
  COMMON_MSG_2:
    ' You will not be able to process any actions for this loan. Please check the auction results and process an auction return if available.',
};

export const SURPLUS = {
  TITLE: 'This customer is due a surplus payment of ',
  CUSTOMER_ORIGINAL_AGREEMENT:
    'Please ask the customer if they have the original agreement(s) or print the relevant affidavit(s)',
  AGREEMENT_NUMBER: 'Agreement Number',
  PRINT_AFFIDAVIT: 'Print Affidavit',
  PRINT_CUSTOMER_MANDATE: 'Print Customer Mandate',
  SURPLUS_PAYMENT: 'Surplus Payment',
  SURPLUS_PAYMENTS: 'Surplus Payment(s)',
  AFFIDAVIT: 'Affidavit(s)',
  PAY_TO_CUSTOMER: 'Pay to Customer',
  ADD_TO_BASKET: 'Add to Basket',
};

export const RATE_MODIFICATION = {
  BASKET_PERCENTAGE: 'Basket %',
  MODIFY_PERCENTAGE: 'Modified %',
  MONTHLY_RATES: 'Monthly Rate',
  APR_PRECANTAGE: '% APR',
  SELECT_REASON: 'Select Reason for Rate Modification.',
};

export const PLEDGE_POPUP = {
  ALERT_TITLE: 'Please Ask the Customer',
  PICKUP_ALERT_MESSAGE:
    'Are you picking up this Loan or are you exercising your Right to Withdraw ?',
  WITHDRAW_ALERT_MESSAGE:
    'Are you exercising your Right to Withdraw or are you picking up this loan ? ',
};
