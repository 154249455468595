import axios from 'axios';
import {
  getMatrixToken,
  getMatrixStoreId,
  getMatrixUserId,
  getPaymentAccessToken,
  getMatrixEmployeeId,
} from 'utils/cookie';
import { encryptData } from 'utils/encrypt';
import { ALERT, LOGIN_ERROR } from 'globalConstants';
import { getNotification } from './util';

/**
 *
 * @param {*} url
 * @param {*} method
 * @param {*} dataOrParams? pass param with get method its automatically take as parameter,
 * pass param with post method its automatically take as body
 */

let controller = new AbortController();

export async function doRequest(
  url,
  method = 'get',
  dataOrParams,
  contentType = '',
  isCancelToken = false,
  responseType = '',
  authorisationHeaders = null,
  validateUserAndStore = true
) {
  if (validateUserAndStore) {
    if (!getMatrixStoreId() || !getMatrixUserId()) {
      getValidationError();
      return;
    }
  }

  let headers = {
    Accept: 'application/json',
    responseType: responseType,
    'Content-Type': contentType ? contentType : 'application/json',
    Authorization: `Bearer ${getMatrixToken()}`,
    storeId: getMatrixStoreId(),
    userId: getMatrixUserId(),
    employeeId: getMatrixEmployeeId(),
  };
  if (authorisationHeaders) {
    headers = {
      ...headers,
      AuthorizationUsername: authorisationHeaders?.authorisationUserName,
      AuthorizationPassword: encryptData(
        authorisationHeaders?.authorisationPassword
      ),
      TickValue: process.env.REACT_APP_SECRET_KEY,
    };
  }

  const params = method === 'get' ? dataOrParams : {};
  const data = method !== 'get' ? dataOrParams : undefined;

  /** AbortController is used for cancelling previous request, send latest request
   * handling for frequent request on search
   */

  if (controller && isCancelToken) {
    controller.abort();
    controller = new AbortController();
  }

  return await axios({
    url,
    method,
    data,
    timeout: 30 * 1 * 1000, //timeout
    params,
    headers,
    responseType,
    signal: controller?.signal,
  });
}

export async function doPaymentRequest(
  url,
  method = 'get',
  dataOrParams,
  responseType = '',
  isCancelToken = false
) {
  if (!getMatrixStoreId() || !getMatrixUserId()) {
    getValidationError();
    return;
  }

  let headers = {
    Accept: 'application/json',
    responseType: responseType,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getMatrixToken()}`,
    storeId: getMatrixStoreId(),
    userId: getMatrixUserId(),
    paymentAccessToken: getPaymentAccessToken(),
  };

  const params = method === 'get' ? dataOrParams : {};
  const data = method !== 'get' ? dataOrParams : undefined;

  /** AbortController is used for cancelling previous request, send latest request
   * handling for frequent request on search
   */
  if (controller && isCancelToken) {
    controller.abort();
    controller = new AbortController();
  }

  return await axios({
    url,
    method,
    data,
    timeout: 30 * 1 * 1000, //timeout
    params,
    headers,
    responseType,
    signal: controller?.signal,
  });
}

export const printRequest = (printUrl, params = {}, serviceType = 'get') => {
  return doRequest(
    printUrl,
    serviceType,
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

const getValidationError = () => {
  getNotification(ALERT.STATUS.ERROR, LOGIN_ERROR);
};
