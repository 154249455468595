import React, { useState } from 'react';

import styled from 'styled-components';
import { Form, Row, Col, Input } from 'antd';

import { Button } from 'components/common/Button';
import ControlledAlert from 'components/common/ControlledAlert';

import { BuildNumber } from 'BuildNumber';
import { LOGIN_LABELS } from './constant';
import { LOCAL_HOST, VERSION_ALERT_MESSAGE } from 'globalConstants';

import './index.less';

const axios = require('axios').default;

const StyledCol = styled(Col)`
  background: var(--blackPearl);
`;

const StyledVersionRow = styled(Row)`
  color: var(--white);
  text-align: center;

  padding: 50px 0 0;
`;

const StyledRow = styled(Row)`
  padding: 10px 0;
`;

const StyledBackground = styled.img`
  width: 100%;
  height: 100vh;
`;

const StyledLogo = styled.img`
  width: 200px;
  height: 65.81px;
  margin-bottom: 80px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  margin: 20px 0;
  border-color: transparent;
`;

interface loginProps {
  isLoading: boolean;
  loginHandler: (params: any) => void;
}

const Login = ({ isLoading, loginHandler }: loginProps) => {
  const [form] = Form.useForm();

  const [isNewBuildNumber, setIsNewBuildNumber] = useState<boolean>(false);

  const getBuildNumber = async () => {
    try {
      const headers = {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-cache',
      };
      const response = await axios.get(
        `${process.env.REACT_APP_ENV}/manifest.json?timeStamp=${Date.now()}`,
        headers
      );
      if (response.data.build_Number !== BuildNumber) {
        setIsNewBuildNumber(true);
      } else {
        onLoginClick();
      }
    } catch (e: any) {
      onLoginClick();
      console.log(e, 'error');
    }
  };

  const onLoginClick = () => {
    const loginDetails = {
      userName: form.getFieldValue('userName'),
      password: form.getFieldValue('password'),
    };

    loginHandler(loginDetails);
  };

  const onFormLoginClick = () => {
    if (window.location.hostname === LOCAL_HOST) {
      onLoginClick();
    } else {
      getBuildNumber();
    }
  };

  const onOkClick = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="login-wrapper">
        <Row style={{ height: '100%' }}>
          <Col xl={16} md={14} xs={0}>
            <StyledBackground
              src={require('../../assets/images/login_background_image.jpg')}
            />
          </Col>
          <StyledCol xl={8} md={10} xs={24}>
            <Row className="login-form-wrapper">
              <Col span={18} offset={3}>
                <StyledLogo src={require('../../assets/images/logo.png')} />
                <Row>
                  <Col span={24}>
                    <Form
                      id="loginForm"
                      onFinish={onFormLoginClick}
                      layout="vertical"
                      form={form}
                      className="login-form"
                    >
                      <StyledRow>
                        <Col span={24}>
                          <Form.Item
                            label={LOGIN_LABELS.USERNAME}
                            name="userName"
                            className="label-control"
                            rules={[
                              {
                                required: true,
                                message: LOGIN_LABELS.ERROR_MSG.USERNAME,
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g.11899"
                              className="input-control"
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col span={24}>
                          <Form.Item
                            label={LOGIN_LABELS.PASSWORD}
                            name="password"
                            className="label-control"
                            rules={[
                              {
                                required: true,
                                message: LOGIN_LABELS.ERROR_MSG.PASSWORD,
                              },
                            ]}
                          >
                            <Input
                              type={'password'}
                              className="input-control"
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col span={24}>
                          <StyledButton loading={isLoading} htmlType="submit">
                            Login
                          </StyledButton>
                        </Col>
                      </StyledRow>
                      <StyledVersionRow>
                        <Col span={24}>v{BuildNumber}</Col>
                      </StyledVersionRow>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </StyledCol>
        </Row>
      </div>
      {isNewBuildNumber && (
        <ControlledAlert
          width={550}
          visible={isNewBuildNumber}
          isCancelBtn={false}
          message={VERSION_ALERT_MESSAGE.ALERT_LINE_ONE}
          secondaryMessage={VERSION_ALERT_MESSAGE.ALERT_LINE_TWO}
          onClick={onOkClick}
          yesButtonText="Ok"
        />
      )}
    </>
  );
};

export default Login;
