import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';
import { ALERT, PRINTER_MESSAGE, RESPONSE_STATUS } from 'globalConstants';
import { getNotification } from 'utils/util';

/**
 *
 * @param {*} result is arraybuffer data
 *  @param {*} type which type of printer need select for print like normal/thermal printer
 *  @param {*} filename is use for display
 * @returns
 */

const sendToPrinter = async (
  result,
  type = 'printcontract',
  filename,
  options = {}
) => {
  let base64 = btoa(
    new Uint8Array(result?.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  );
  const obj = {
    ImageFileName: filename,
    ImageFile: base64,
  };

  const params = { ...obj, ...options };

  try {
    await doRequest(`${urls.PRINTER_API}/${type}`, 'post', params);

    return RESPONSE_STATUS.SUCCESS;
  } catch (e) {
    getprinterError(e);
    return RESPONSE_STATUS.SERVICE_UNAVAILABLE;
  }
};

const imageFromScanner = async () => {
  try {
    const img = await doRequest(`${urls.PRINTER_API}/scan/png`, 'get');
    return img.data?.ImageFile;
  } catch (e) {
    getprinterError(e);
  }
};

const getprinterError = (e) => {
  getNotification(
    ALERT.STATUS.ERROR,
    e.message,
    PRINTER_MESSAGE.PRINTER_SERVICE_ERROR,
    ALERT.DURATION.LONG
  );
};

export { sendToPrinter, imageFromScanner };
